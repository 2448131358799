import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useGolfAssessments() {
  const RESOURCE = '/assessment/golf'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'UNIQUE KEY', value: 'uniqueKey',
    },
    {
      text: 'TITLE OF SESSION', value: 'titleOfSession',
    },
    {
      text: 'SESSION TYPE', value: 'sessionType',
    },
    {
      text: 'CATEGORY', value: 'category',
    },
    {
      text: 'LEVEL', value: 'level',
    },
    {
      text: 'FREQUENCY', value: 'frequency',
    },
    {
      text: 'MEASUREMENT', value: 'measurement',
    },
    {
      text: 'PRACTICE TYPE', value: 'practiceType',
    },
    {
      text: 'GOAL', value: 'goal', //
    },
    {
      text: 'EXERCISE', value: 'exercise',
    },
    {
      text: 'EDM', value: 'entryDataMethod', //
    },
    {
      text: 'ATTEMPTS', value: 'attempts', //
    },
    {
      text: 'DISTANCE', value: 'distance', //
    },
    {
      text: 'DISTANCE UNIT', value: 'distanceUnit', //
    },
    {
      text: 'ACTIVE', value: 'active', width: 100,
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]
  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankGolfAssessment = {
    id: '',
    uniqueKey: '',
    sessionType: '',
    titleOfSession: '',
    category: '',
    level: '',
    frequency: '',
    measurement: '',
    practiceType: '',
    goal: '',
    entryDataMethod: '',
    attempts: '',
    distance: '',
    distanceUnit: '',
    exercise: null,
    exerciseUniqueKey: '',
  }

  const golfAssessments = ref([])
  const golfAssessment = ref(structuredClone(blankGolfAssessment))

  const isLoading = ref(false)

  const createGolfAssessment = async golfAssessmentItem => {
    isLoading.value = true

    await axios.post(RESOURCE, golfAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully created a new golf assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new golf assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteGolfAssessment = async golfAssessmentItem => {
    isLoading.value = true
    golfAssessmentItem.deleted = true

    await axios.put(RESOURCE, golfAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully deleted the golf assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to delete the golf assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const fetchGolfAssessments = async () => {
    isLoading.value = true

    await axios.get(RESOURCE)
      .then(response => {
        golfAssessments.value = response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch golf assessments:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateGolfAssessment = async golfAssessmentItem => {
    isLoading.value = true

    await axios.put(RESOURCE, golfAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully updated the golf assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the golf assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateManyGolfAssessments = async (type, paramName, paramValue, ids) => {
    isLoading.value = true
    
    const payload = {
      type,
      params: [
        {
          name: paramName,
          value: paramValue,
          ids
        }
      ]
    }
    
    try {
      await axios.put('/library/updateMany', payload)
      
      showSnackbar(
        `Successfully updated ${ids.length} golf assessment(s)`, 
        SnackbarType.SUCCESS
      )
      
      return true
    } catch (error) {
      const message = error.response?.data?.message ?? error.message
      const statusCode = error.response?.status ?? null
      
      showSnackbar(
        `Failed to update golf assessments:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
        SnackbarType.ERROR
      )
      
      throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankGolfAssessment,
    golfAssessments,
    golfAssessment,

    isLoading,

    createGolfAssessment,
    deleteGolfAssessment,
    fetchGolfAssessments,
    updateGolfAssessment,
    updateManyGolfAssessments,
  }
}
