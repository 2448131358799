<template>
  <v-tabs v-model="tabsNavigation"  show-arrows>
    <v-tab>Golf Skills Assessment</v-tab>
    <v-tab>Exercise</v-tab>

    <v-tabs-items v-model="tabsNavigation" class="pa-5">
      <v-tab-item>
        <dl>
          <dt>Id</dt>
          <dd>{{ item.id }}</dd>

          <dt>Unique key</dt>
          <dd>{{ item.uniqueKey }}</dd>

          <dt>Title of session</dt>
          <dd>{{ item.titleOfSession }}</dd>

          <dt>Session type</dt>
          <dd>{{ item.sessionType }}</dd>

          <dt>Category</dt>
          <dd>{{ item.category }}</dd>

          <dt>Level</dt>
          <dd>{{ item.level }}</dd>

          <dt>Frequency</dt>
          <dd>{{ item.frequency }}</dd>

          <dt>Measurement</dt>
          <dd>{{ item.measurement }}</dd>

          <dt>Practice type</dt>
          <dd>{{ item.practiceType }}</dd>

          <dt>Goal</dt>
          <dd>{{ item.goal }}</dd>

          <dt>Entry data method</dt>
          <dd>{{ item.entryDataMethod }}</dd>

          <dt>Attempts</dt>
          <dd>{{ item.attempts }}</dd>

          <dt>Distance</dt>
          <dd>{{ item.distance }}</dd>

          <dt>Distance unit</dt>
          <dd>{{ item.distanceUnit }}</dd>
        </dl>
      </v-tab-item>

      <v-tab-item>
        <exercise-details-tab-content :exercise="item.exercise" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { ref } from 'vue'
import ExerciseDetailsTabContent from '@/components/ExerciseDetailsTabContent.vue'

export default {
  components: {
    ExerciseDetailsTabContent,
  },

  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  setup() {
    const tabsNavigation = ref(0)

    return {
      tabsNavigation,
    }
  },
}
</script>
